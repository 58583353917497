import React from 'react';
import ScheduleDisplay from '../components/ScheduleDisplay';
import PriceScreen from '../components/PriceScreen';
import PriceScreenCenter from '../components/PriceScreenCenter';
import Edifice511Data from '../content/edifice-511.json';
import AutoRefresh from '../components/AutoRefreshProps';

function Edifice511() {
  const dayContent = (
    <PriceScreen
      title={Edifice511Data.day.title}
      lines={Edifice511Data.day.lines}
    />
  );

  const nightContent = (
    <PriceScreenCenter
      title={Edifice511Data.night.title}
      lines={Edifice511Data.night.lines}
    />
  );

  return (
    <div>
      <AutoRefresh intervalms={900000} /> {/* 15 min */}
      <ScheduleDisplay
        dayContent={dayContent}
        nightContent={nightContent}
        dayStartTime="03:00:00"
        nightStartTime="16:00:00"
      />
    </div>
  );
}

export default Edifice511;
